import { ref, watch } from "vue"
import { current_user } from "./current-user"

export const access_token = ref<string | null>('')
watch(access_token, () => {
    if (null === access_token.value) {
        localStorage.removeItem('access_token')
        current_user.value = null
    } else {
        localStorage.setItem('access_token', access_token.value)
    }
})