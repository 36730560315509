<template>
    <form @submit.stop.prevent="signin">
        <div class="form-floating">
            <input type="email" class="form-control" required v-model="email" placeholder="E-mail" />
            <label for="email">E-mail</label>
        </div>
        <div class="form-floating">
            <input type="password" class="form-control" required v-model="senha" placeholder="Senha" />
            <label for="senha">Senha</label>
        </div>
        <button class="w-100 btn btn-lg btn-primary mt-2" type="submit" v-if="!loading">
            Entrar
        </button>
        <button class="w-100 btn btn-lg btn-primary mt-2" type="submit" v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Carregando...
        </button>
    </form>
</template>
<script lang="ts" setup>

import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { auth_service } from '@/store/auth'
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { isAxiosError } from 'axios';

const email = ref<string>('')
const senha = ref<string>('')
const loading = ref<boolean>(false)

const $router = useRouter()

const signin = async () => {
    try {
        loading.value = true;
        await auth_service.login({
            email: email.value,
            senha: senha.value
        })

        $router.replace('/');

    } catch (err) {
        let text = 'Não foi possível enviar o formulário';
        if (isAxiosError(err)) {
            text = err.response?.data.error
        } else if (err instanceof Error) {
            text = err.message
        }
        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        });
    }
    loading.value = false;
}


</script>
<style lang="less" scoped>
input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

input[type="password"] {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

select {
    margin-bottom: -1px;
    border-radius: 0;
}
</style>