import type { TipoUsuario } from "./tipo-usuario"

export class Filter {
    id?: string
    ids?: string[]
    matricula?: string
    matriculas?: string[]
    email?: string
    emails?: string[]
    tipo?: TipoUsuario
    tipos?: TipoUsuario[]
    nome?: string
}