import type { AxiosInstance } from "axios";
import type { LoginRequest } from "./login-request";
import { type Usuario } from "../usuarios";
import type { LoginResponse } from "./login-response";
import { current_user } from "./current-user";
import { access_token } from "./access-token";

export class AuthService {
    constructor(
        private api: AxiosInstance
    ) { }

    async login({ email, senha }: LoginRequest): Promise<LoginResponse> {
        const { data } = await this.api.post<LoginResponse>(`/auth/signin`, {
            email,
            senha
        })

        current_user.value = data.usuario
        access_token.value = data.access_token

        return data
    }

    async logout() {
        current_user.value = null
        access_token.value = null
    }

    async me(): Promise<Usuario> {
        const { data } = await this.api.get<Usuario>(`/auth/me`)
        return data
    }
}