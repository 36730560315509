import api from '@/lib/api'
import { AuthService } from './auth-service'
import { access_token } from './access-token'
import { watch } from 'vue'
import { current_user } from './current-user'

export * from './login-request'
export * from './login-response'
export * from './auth-service'
export * from './current-user'
export * from './access-token'

export const auth_service = new AuthService(api)


const start = async () => {
    try {
        current_user.value = await auth_service.me();

    } catch (error) {
        current_user.value = null
    }
}

start()

watch(access_token, async () => {
    try {
        if (!access_token.value) {
            throw new Error("Unauthenticated");

        }
        current_user.value = await auth_service.me()
    } catch (error) {
        current_user.value = null
    }
})