import { ref } from "vue"
import api from "@/lib/api"
import { UsuarioRepository } from "./usuario-repository"
import type { Usuario } from "./usuario"

export * from './tipo-usuario'
export * from './usuario'
export * from './filter'
export * from './usuario-repository'

export const usuario_repository = new UsuarioRepository(api)

export const loading = ref<boolean>(false)
export const usuarios = ref<Usuario[]>([])

export const load = async (): Promise<void> => {
    try {
        loading.value = true
        usuarios.value = await usuario_repository.search()
    } catch (error) {
        console.error(error)
        loading.value = false
    }
}

load()

usuario_repository.addEventListener('changed', load)